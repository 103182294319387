import React, { useState, useEffect } from "react";
import { useTheme } from "@mui/material/styles";
import { useMediaQuery, Grid } from "@mui/material";
import moment from "moment";
import { HOST_PORT } from "../../env";

const Record = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const [toggle, setToggle] = useState(true);
  // const [totalattendence,setTotalAttendence] = useState()


  const openToggle = () => {
    setToggle(!toggle);
  };

  const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const defaultDate = getCurrentDate();
  const [punchint, setPunchInt] = useState([]);

  const Punchintime = () => {
    let userInfo = JSON.parse(localStorage.getItem("user-info"));
    // console.log(userInfo, "token");
    let id = userInfo.id;
    // console.log(id, "id");
    fetch(HOST_PORT+`hrms/punchin_punchout_all/${id}`)
      .then((res) => res.json())
      .then((data) => {
        setPunchInt(data.data);
     
      });
  };

  // console.log(punchint?.punchin, "punchint");
  // console.log(punchint, "punchint");
  // const data1= punchint?.punchin;
  // console.log(data1?.split(""),"data")

  useEffect(() => {
    Punchintime();
  }, []);


  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            padding: 20,
            margin: 10,
            background: "#FFF",
            width: "87%",
            borderRadius: 20,
          }}
        >
          <div>
            <div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <div style={{ fontSize: "16px", fontWeight: "bold" }}>
                  My Attendance Records
                </div>
                <div
                  onClick={openToggle}
                  style={{
                    backgroundColor: "#0A2558",
                    width: "25px",
                    borderRadius: "50%",
                    justifyContent: "center",
                    alignContent: "center",
                    display: "flex",
                  }}
                >
                  {toggle ? (
                    <i
                      className="bx bx-chevron-down"
                      style={{ color: "white", marginTop: "18%" }}
                    ></i>
                  ) : (
                    <i
                      className="bx bx-chevron-up"
                      style={{ color: "white", marginTop: "18%" }}
                    ></i>
                  )}
                </div>
              </div>
              {toggle && (
                <div>
                  <hr />
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={12}>
                      <div style={{ fontSize: "14px" }}>Date*</div>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <input
                          size="small"
                          type="date"
                          value={defaultDate}
                          // onChange={(e) => setDefaultDate(e.target.value)}
                          style={{
                            width: "200px",
                            border: "1px solid grey",
                            padding: "7px",
                            borderRadius: "5px",
                            backgroundColor: "#F2F2F2",
                          }}
                        />
                      </div>
                    </Grid>
                  </Grid>
                  <hr />
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <div>
                      <div style={{ fontSize: "14px" }}>*Required</div>
                    </div>
                    <div>
                      <button
                        type="button"
                        className="btn btn-success"
                        style={{
                          borderRadius: "20px",
                          backgroundColor: "#0A2558",
                        }}
                      >
                        View
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            padding: 20,
            margin: 10,
            background: "#FFF",
            width: "87%",
            borderRadius: 10,
            overflowX: "scroll",
            marginTop: !matches ? "1%" : "15%",
          }}
        >
          <div style={{ justifyContent: "flex-end", display: "flex" }}>
            <p>Total Duration (Hours): 24.00</p>
          </div>
          <hr />
          <table
            style={{
              border: "1px solid",
              width: "100%",
              textAlign: "center",
              justifyContent: "center",
            }}
          >
            {/* <thead>
              <tr>
                <th style={{ border: "1px solid" }}>.</th>
                <th style={{ border: "1px solid" }}>.</th>
                <th style={{ border: "1px solid" }}>.</th>

                <th style={{ textAlign: "center" }}>12/10/2023</th>
                <th style={{ textAlign: "center" }}>12/10/2023</th>
                <th style={{ textAlign: "center" }}>12/10/2023</th>
                <th style={{ textAlign: "center" }}>12/10/2023</th>
                <th style={{ textAlign: "center" }}>12/10/2023</th>
              </tr>
            </thead> */}
            <tbody>
              <tr>
                <td style={{ textAlign: "center", border: "1px solid" }}>
                  S.no
                </td>
                <td style={{ textAlign: "center", border: "1px solid" }}>
                  Date
                </td>
                <td style={{ textAlign: "center", border: "1px solid" }}>
                  Punch_In
                </td>
                <td style={{ textAlign: "center", border: "1px solid" }}>
                  Punch_In_Notes
                </td>
                <td style={{ textAlign: "center", border: "1px solid" }}>
                  Punch_Out
                </td>
                <td style={{ textAlign: "center", border: "1px solid" }}>
                  Punch_Out_Notes
                </td>
                <td style={{ textAlign: "center", border: "1px solid" }}>
                  Duration_(Hours)
                </td>
                <td style={{ textAlign: "center", border: "1px solid" }}>
                  Remark
                </td>
              </tr>
              {punchint?.map((record,i) => {
                const punchinTime = moment(record.punchin);
                const punchoutTime = moment(record.punchout);

                var hoursDiff = punchoutTime.diff(punchinTime, "hours") % 60;

                var minutesDiff =
                  punchoutTime.diff(punchinTime, "minutes") % 60;

                var secondsDiff =
                  punchoutTime.diff(punchinTime, "seconds") % 60;

                var a;
                if (record.punchin && record.punchout) {
                  a = record.punchout.split("T")[1].split(".")[0];
                
                } else {
                  a = "";
                }
                // console.log(hoursDiff+":"+minutesDiff+":"+secondsDiff,"i")
                // setTotalAttendence(`${hoursDiff}${minutesDiff}${secondsDiff}`)
                // console.log(totalattendence,"dfjdsghj")
                // for(let i=0; i<=punchint.length;i++){
                //   if()
                // }
                // setTotalAttendence(a)

                return (
                  <tr>
                    <td style={{ textAlign: "center", border: "1px solid" }}>
                      {/* {record.punchin.split("T")[0]} */}{i+1}
                    </td>
                    <td style={{ textAlign: "center", border: "1px solid" }}>
                      {record.punchin.split("T")[0]}
                    </td>
                    <td style={{ textAlign: "center", border: "1px solid" }}>
                      {record.punchin.split("T")[1].split(".")[0]}
                    </td>
                    <td style={{ textAlign: "center", border: "1px solid" }}>
                      {record.punchin_note}
                    </td>
                    <td style={{ textAlign: "center", border: "1px solid" }}>
                      {/* {record.punchout.split("T")[1].split(".")[0]} */}
                      {/* {record.punchin ? record.punchout.split("T")[1].split(".")[0] : ""} */}
                      {a}
                    </td>
                    <td style={{ textAlign: "center", border: "1px solid" }}>
                      {record.punchout_note}
                    </td>
                    <td style={{ textAlign: "center", border: "1px solid" }}>
                      {a ?
                    [hoursDiff + ":" + minutesDiff + ":" + secondsDiff]
                    :""}
                    </td>
                    <td style={{ textAlign: "center", border: "1px solid" }}>
                      {record.remark}
                    </td>
                  </tr>
                  
                );
             
              })}
            </tbody>
          </table>
          
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            padding: 20,
            margin: 10,
            background: "#FFF",
            width: "87%",
            borderRadius: 20,
          }}
        >
          <table
            style={{
              border: "1px solid",
              width: "100%",
              textAlign: "center",
              justifyContent: "center",
            }}
          >
            <tbody>
              <tr>
                <td style={{ textAlign: "center", border: "1px solid" }}>
                  Total Attendance
                </td>
                <td style={{ textAlign: "center", border: "1px solid" }}>
                  Paid Leaves
                </td>
                <td style={{ textAlign: "center", border: "1px solid" }}>
                  Unpaid Leaves
                </td>
                <td style={{ textAlign: "center", border: "1px solid" }}>
                  Seak Leaves
                </td>
              </tr>
             
              <tr>
                <td style={{ textAlign: "center", border: "1px solid" }}>
               {punchint.length}
                </td>
                <td style={{ textAlign: "center", border: "1px solid" }}>
                {/* {punchint.length * totalattendence} */}
                </td>
                <td style={{ textAlign: "center", border: "1px solid" }}>
                  {/* erwtrg */}
                </td>
                <td style={{ textAlign: "center", border: "1px solid" }}>
                  {/* erwtrg */}
                </td>
              </tr>
          
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Record;
