import React,{useState,useEffect} from "react";
import { Grid, TextField, Button } from "@mui/material";
import "../attendance/attendence.css";
import { HOST_PORT } from "../../env";

export const Attendance = () => {
  const [punchint, setPunchInt] = useState([]);

  const Punchintimeget = () => {
    let userInfo = JSON.parse(localStorage.getItem("user-info"));
    // console.log(userInfo, "token");
    let id = userInfo.id;
    console.log(id, "id");
    fetch(HOST_PORT`hrms/punchin_punchout_admin/`)
      .then((res) => res.json())
      .then((data) => {
        setPunchInt(data.data);
        // console.log(punchint, "punchint");
      });
  };
  // console.log(punchint, "punchint");

  useEffect(() => {
    Punchintimeget();
  }, []);

  return (
    <div>
      <div
        style={{
          display: "flex",
          // justifyContent: "space-between",
          flexDirection: "row",
          backgroundColor: "#0A2558",
        }}
      >
        <Grid
          container
          spacing={2}
          style={{ display: "flex", justifyContent: "center" }}
        >
          <Grid item xs={12} md={4}>
            <div
              style={{
                fontWeight: "bold",
                marginTop: "4%",
                color: "white",
              }}
            >
              Monthly Attendance Report
            </div>
          </Grid>
          <Grid item xs={12} md={4}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                // justifyContent: "space-around",
              }}
            >
              <div style={{ marginTop: "3%", color: "white" }}>
                Choose Months
              </div>
              <div>
                <TextField
                  type="month"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    inputProps: {
                      min: "yyyy-MM",
                    },
                  }}
                  sx={{ m: 1, minWidth: 180 }}
                  size="small"
                  style={{ backgroundColor: "white", borderRadius: "5px" }}
                />
              </div>
            </div>
          </Grid>
          <Grid item xs={12} md={4}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                // justifyContent: "space-evenly",
              }}
            >
              <span>
                <Button
                  variant="contained"
                  sx={{ m: 1, minWidth: 180 }}
                  style={
                    {
                      // backgroundColor: "#D6EEEE",
                    }
                  }
                >
                  SUBMIT
                </Button>
              </span>
            </div>
          </Grid>
        </Grid>
      </div>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <div className="container table-responsive py-5">
          <table
            className="table table-bordered"
            style={{ textAlign: "center" }}
          >
            <thead className="thead-dark" style={{ textAlign: "center" }}>
              <tr>
                <th colSpan="3 " style={{ textAlign: "center" }}>
                  Date
                </th>
              </tr>
              <tr>
                <th scope="col">S.no.</th>
                <th scope="col">
                  <div>Employee</div>Name
                </th>
                <th scope="col">
                  <div>Employee</div>Id
                </th>
              </tr>
            </thead>
            <tbody>
              {/* {punchint?.map((index, record) => {
                return (
                  <tr>
                    <th scope="row">{record.no}</th>
                    <td>S
                      {record.first_name}
                    </td>
                    <td>{record.employee_id}</td>
                  </tr>
                );
              })} */}
              <tr>
                <th scope="row">1</th>
                <td>S</td>
                <td>A001</td>
              </tr>
              <tr>
                <th scope="row">1</th>
                <td>S</td>
                <td>A001</td>
              </tr>
              <tr>
                <th scope="row">1</th>
                <td>S</td>
                <td>A001</td>
              </tr>
              <tr>
                <th scope="row">1</th>
                <td>S</td>
                <td>A001</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="container table-responsive py-5">
          <table class="table table-bordered mr-10">
            <thead class="thead-dark">
            <tr>
              {/* {punchint.map((punchindate) =>(

                
               
                <th colSpan="3 " style={{ textAlign: "center" }}>
                  {punchindate.punchin}
                </th>
             
              ))
              } */}
               </tr>
              <tr style={{ textAlign: "center" }}>
                <th scope="col">Punch In</th>
                <th scope="col">Punch Out</th>
                <th scope="col">Duration</th>
                <th scope="col">Punch In</th>
                <th scope="col">Punch Out</th>
                <th scope="col">Duration</th>
                <th scope="col">Punch In</th>
                <th scope="col">Punch Out</th>
                <th scope="col">Duration</th>
                <th scope="col">Punch In</th>
                <th scope="col">Punch Out</th>
                <th scope="col">Duration</th>
                <th scope="col">Punch In</th>
                <th scope="col">Punch Out</th>
                <th scope="col">Duration</th>
                <th scope="col">Punch In</th>
                <th scope="col">Punch Out</th>
                <th scope="col">Duration</th>
              </tr>
            </thead>
            <tbody style={{ textAlign: "center" }}>
              <tr>
                <td>9:30am</td>
                <td>6:00pm</td>
                <td>8:30</td>
                <td>9:30am</td>
                <td>6:00pm</td>
                <td>8:30</td>
                <td>9:30am</td>
                <td>6:00pm</td>
                <td>8:30</td>
                <td>9:30am</td>
                <td>6:00pm</td>
                <td>8:30</td>
                <td>9:30am</td>
                <td>6:00pm</td>
                <td>8:30</td>
                <td>9:30am</td>
                <td>6:00pm</td>
                <td>8:30</td>
              </tr>
              <tr>
                <td>9:30am</td>
                <td>6:00pm</td>
                <td>8:30</td>
                <td>9:30am</td>
                <td>6:00pm</td>
                <td>8:30</td>
                <td>9:30am</td>
                <td>6:00pm</td>
                <td>8:30</td>
                <td>9:30am</td>
                <td>6:00pm</td>
                <td>8:30</td>
                <td>9:30am</td>
                <td>6:00pm</td>
                <td>8:30</td>
                <td>9:30am</td>
                <td>6:00pm</td>
                <td>8:30</td>
              </tr>
              <tr>
                <td>9:30am</td>
                <td>6:00pm</td>
                <td>8:30</td>
                <td>9:30am</td>
                <td>6:00pm</td>
                <td>8:30</td>
                <td>9:30am</td>
                <td>6:00pm</td>
                <td>8:30</td>
                <td>9:30am</td>
                <td>6:00pm</td>
                <td>8:30</td>
                <td>9:30am</td>
                <td>6:00pm</td>
                <td>8:30</td>
                <td>9:30am</td>
                <td>6:00pm</td>
                <td>8:30</td>
              </tr>
              <tr>
                <td>9:30am</td>
                <td>6:00pm</td>
                <td>8:30</td>
                <td>9:30am</td>
                <td>6:00pm</td>
                <td>8:30</td>
                <td>9:30am</td>
                <td>6:00pm</td>
                <td>8:30</td>
                <td>9:30am</td>
                <td>6:00pm</td>
                <td>8:30</td>
                <td>9:30am</td>
                <td>6:00pm</td>
                <td>8:30</td>
                <td>9:30am</td>
                <td>6:00pm</td>
                <td>8:30</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
