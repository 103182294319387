import React from 'react'
import './loader.css'

export const Loader = () => {
    return (
        <div className="adorned-loader">
          {/* You can add decorative elements or styles here */}
          <div className="loader"></div>
        </div>
      );
    };
