import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from "./components/login/Login";
import { Sidebar } from "./components/sidebar/Sidebar";

function App() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/sidebar/*" element={<Sidebar />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
