import React, { useState } from "react";
import { MDBInput } from "mdb-react-ui-kit";
import { MDBBtn } from "mdb-react-ui-kit";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import { useNavigate } from "react-router-dom";
import HumHaiLoginHeaderLogo from "../../assets/images/hum-hai-header.png";
import { Circles } from "react-loading-icons";
// import { MuiOtpInput } from "mui-one-time-password-input";
// import { signInUser } from "../../redux/authSlice";
// import { useDispatch } from "react-redux";
import "../../assets/css/style.css";
// import LoginIcon from "@mui/icons-material/Login";
import bg from "../../assets/images/user-interface.png";
import { Loader } from "../../loader/Loader";
import { HOST_PORT } from "../../env";


const Login = () => {

  const getCookie = (name) => {
    var cookieValue = null;
    if (document.cookie && document.cookie !== "") {
      var cookies = document.cookie.split(";");
      for (var i = 0; i < cookies.length; i++) {
        var cookie = cookies[i].trim();
        if (cookie.substring(0, name.length + 1) === name + "=") {
          cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
          break;
        }
      }
    }
    return cookieValue;
  };

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  // const [work, setWork] = useState("");
  const [incorrect, setIncorrect] = useState(false);
  const [incorrect1, setIncorrect1] = useState(false);
  const [success, setSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  // const [centredModal, setCentredModal] = useState(false);
  // const [centredModal1, setCentredModal1] = useState(false);
  // const [centredModal2, setCentredModal2] = useState(false);

  // const openModal = () => setCentredModal(!centredModal);
  // const openModal2 = () => setCentredModal2(!centredModal2);
  // const [otp, setOtp] = useState("");

  const handleClick = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    var csrftoken = getCookie("csrftoken");
    // console.log(username, password, "username");
    let result = await fetch(HOST_PORT+`hrms/LoginAPI/`, {
      // let result = await fetch("http://192.168.1.33:8000/login-app/", {
      method: "POST",
      body: JSON.stringify({ username: username, password: password }),
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Accept: "application/json",
        "X-CSRFToken": csrftoken,
      },
    })
      .then((res) => {
        // console.log(res.token, "response");
        return res;
      })
      .catch((error) => {
        // console.log(error, "error");
      });

    // console.log("after response");
    result = await result.json();
    // console.log(result.detail, "result1");
    // console.log(result.status, "true");
    if(result.status === true){
       localStorage.setItem("user-info", JSON.stringify(result.user_data));
      // console.log(data,"data")
      localStorage.setItem("token", JSON.stringify({login:true,token:result.token}));
      // console.log("token",result.token)
      // let userInfo = JSON.parse(localStorage.getItem("user-info"));
      // let user1 = userInfo?.first_name;
  // let user2 = userInfo.user_data.last_name;
      // console.log(user1,"user111")

      setSuccess(true);

      setTimeout(() => {
        navigate("/sidebar/time");
        setSuccess(false);
      }, 3000);

      setIsLoading(false);
    }
    // else{
    //   // alert(result.detail)
    //   setIncorrect1(true);
    //   setIsLoading(false);
    //   //   setIncorrect(false);
    // }
    if (result.detail === "User not Found!") {
      setIncorrect1(true);
      setIncorrect(false);
      setIsLoading(false);
    }
    if (result.detail === "Incorrect Password!") {
      // console.log("hi")
      setIncorrect(true);
      setIncorrect1(false);
      setIsLoading(false);
    }

    // console.log(result.msg);
  };


  //   const dispatch = useDispatch()
  //  const handleClick = async (e) =>{
  //   e.preventDefault()
  //   console.log(username,password)
  //   dispatch(signInUser({username,password}))
  //  }

  return (
    <>
      <div className="full-screen-background">
        {success ? (
          <Stack sx={{ width: "100%" }} spacing={2}>
            <Alert severity="success">
              This is a success alert — check it out!
            </Alert>
          </Stack>
        ) : (
          ""
        )}
        {isLoading ? (
          <div
            md={6}
            style={{
              position: "absolute",
              top: "30%",
              right: "30%",
              zIndex: 1,
            }}
          >
            {" "}
            <Circles stroke="#0000FF" />
          </div>
        ) : (
          ""
        )}

        <div className="container-fluid" style={{ marginTop: "10%" }}>
        
          <div className="main-container row">
            <div className="col-md-6 text-center">
              <img
                className="img-fluid"
                src={HumHaiLoginHeaderLogo}
                alt="Hum Hai Logo Header"
              />
            </div>

            <div className="col-md-5 mt-3">
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <img src={bg} style={{ width: "45px" }} alt="bg"/>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div style={{ display: "flex", flexDirection: "column" }}></div>
                <div>
                  <p style={{ fontSize: "25px", fontWeight: "bold" }}>Login</p>
                </div>
              </div>

              <form onSubmit={handleClick}>
                <div className="container-fluid">
                  <div className="row">
                    {incorrect ? (
                      <Stack sx={{ width: "100%" }} spacing={2}>
                        <Alert severity="error">
                          {" "}
                          Incorrect login credentials. Please check you username
                          and password and try again !!
                        </Alert>
                      </Stack>
                    ) : (
                      ""
                    )}
                    {incorrect1 ? (
                      <Stack sx={{ width: "100%" }} spacing={2}>
                        <Alert severity="error"> User does not exist !!</Alert>
                      </Stack>
                    ) : (
                      ""
                    )}

                    <div className="col-12 my-2">
                      <MDBInput
                        label="Username"
                        id="formControlLg"
                        type="text"
                        size="lg"
                        maxLength={10}
                        required
                        onChange={(e) => {
                          setUsername(e.target.value);
                        }}
                        value={username}
                      />
                      <br />
                      <MDBInput
                        label="Password"
                        id="typePassword"
                        type="password"
                        size="lg"
                        // maxLength={10}
                        required
                        onChange={(e) => {
                          setPassword(e.target.value);
                        }}
                        value={password}
                      />
                      <div className="d-grid gap-2 my-2">
                        <MDBBtn type="submit">Submit</MDBBtn>
                      </div>
                      {/* <Link onClick={() => setCentredModal(true)}></Link> */}
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    
    </>
  );
};

export default Login;
