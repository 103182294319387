import React, { useState, useEffect,useRef } from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useTheme } from "@mui/material/styles";
import { useMediaQuery, Grid, TextField, Button } from "@mui/material";
import moment from "moment";
import { CSVLink } from "react-csv";
import { HOST_PORT } from "../../env";
import { Circle } from "@mui/icons-material";
import { Loader } from "../../loader/Loader";

export const AdminRecord = () => {
  const getCookie = (name) => {
    var cookieValue = null;
    if (document.cookie && document.cookie !== "") {
      var cookies = document.cookie.split(";");
      for (var i = 0; i < cookies.length; i++) {
        var cookie = cookies[i].trim();
        if (cookie.substring(0, name.length + 1) === name + "=") {
          cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
          break;
        }
      }
    }
    return cookieValue;
  };
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  const [punchint, setPunchInt] = useState([]);
  const [punchint1, setPunchInt1] = useState([]);
  const [currentAttendence, setCurrentAttendence] = useState(true);
  const [employeeid, setEmployee] = useState();
  const [allAttendence, setAllAttendence] = useState();
  const [date, setDate] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const Punchintimeget = () => {
    let userInfo = JSON.parse(localStorage.getItem("user-info"));
    // console.log(userInfo, "token");
    let id = userInfo.id;
    // console.log(id, "id");
    fetch(HOST_PORT + `hrms/punchin_punchout_admin/`)
      .then((res) => res.json())
      .then((data) => {
        setPunchInt(data.data);
        setPunchInt1(data.emp);
        setCurrentAttendence(true);
      });
    };
    
    // console.log(punchint,punchint1, "punchint");

  useEffect(() => {
    Punchintimeget();
  }, []);

  const Employeedata = async (e) => {
    // console.log(date, employeeid, "date employeeid");

    e.preventDefault();
    setIsLoading(true)
    var csrftoken = getCookie("csrftoken");
    let dataget = await fetch(
      HOST_PORT+`hrms/punchin_punchout_admin/`,
      {
        // method:"POST",
        // body:JSON.stringify({date,employeeid})
        method: "POST",
        body: JSON.stringify({ date: date, employeeid: employeeid }),
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Accept: "application/json",
          "X-CSRFToken": csrftoken,
        },
      }
    )
      .then((res) => {
        console.log(res.data, "response");
        return res;
      })
      .catch((error) => {
        // console.log(error, "error");
      });

    // console.log("after response");
    dataget = await dataget.json();
    console.log(dataget, "dataget1");
    setAllAttendence(dataget.data);
    if(dataget){
      setIsLoading(false)
    }
    setCurrentAttendence(false);
    // console.log(allAttendence, "allAttendence");
    // console.log(dataget.status, "true");
  };
  // console.log(employeeid, "employeeid");


  const [dataForDownload, setDataForDownload] = useState([]);
  const [bDownloadReady, setDownloadReady] = useState(false);
  const csvLink = useRef(null); // Add this line to create a ref for CSVLink

  useEffect(() => {
    if (csvLink && csvLink.current && bDownloadReady) {
      csvLink.current.link.click();
      setDownloadReady(false);
    }
  }, [bDownloadReady]);

  const handleAction = (actionType) => {
    if (actionType === 'DOWNLOAD') {
      // get data here
      setDataForDownload(allAttendence);
      setDownloadReady(true);
    }
  }

  return (
    <div>
 {isLoading ? (
          <div
            md={6}
            style={{
              position: "absolute",
              top: "30%",
              right: "30%",
              zIndex: 1,
            }}
          >
            
            <Loader/>
          </div>
        ) : (
          ""
        )}
      <form onSubmit={Employeedata}>
        <div>
          <Grid
            container
            spacing={2}
            style={{ display: "flex", justifyContent: "center", background: '#0A2558' }}
          >
            <Grid item xs={12} md={4}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-evenly",
                }}
              >
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <div style={{ marginTop: "5%", color: 'white' }}>Employee Name</div>
                  <div>
                    <FormControl
                      sx={{ m: 1, minWidth: 180 }}
                      size="small"
                      style={{ color: "white" }}
                    >
                      <InputLabel id="demo-select-small-label">
                        Employees
                      </InputLabel>
                      <Select
                        labelId="demo-select-small-label"
                        id="demo-select-small"
                        label="Employees"
                        value={employeeid}
                        onChange={(e) => setEmployee(e.target.value)}
                        style={{ backgroundColor: "#F2F2F2" }}
                      >
                        <MenuItem value="">
                          <em>Employee Name</em>
                        </MenuItem>
                        {punchint1.map((record) => (
                          <MenuItem value={record.code}>{record.code}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} md={4}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-evenly",
                }}
              >
                <span style={{ marginTop: "4%", color: 'white' }}>To Date </span>
                <span>
                  <TextField
                    type="month"
                    value={date}
                    onChange={(e) => setDate(e.target.value)}
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      inputProps: {
                        min: "yyyy-MM",
                      },
                    }}
                    sx={{ m: 1, minWidth: 180 }}
                    size="small"
                    style={{ backgroundColor: "white", borderRadius: "5px" }}
                  />
                </span>
              </div>
            </Grid>
            <Grid item xs={12} md={4}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-evenly",
                }}
              >
                <span>
                  <Button
                    variant="contained"
                    sx={{ m: 1, minWidth: 180 }}
                    style={{
                      // backgroundColor: "#0A2558",
                    }}
                    type="submit"
                  >
                    SUBMIT
                  </Button>
                </span>
              </div>
            </Grid>
          </Grid>
        </div>
      </form>
     
      {currentAttendence ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              padding: 20,
              margin: 10,
              background: "#FFF",
              width: "100%",
              borderRadius: 10,
              overflowX: "scroll",
              marginTop: !matches ? "1%" : "15%",
            }}
          >
            <div>
              <div>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={12}>
                    <div style={{ fontWeight: 'bold', margin: '1%' }}>Daily Employee Attendance</div>
                  </Grid>
                </Grid>
              </div>
              <table
                style={{
                  border: "1px solid",
                  width: "100%",
                  textAlign: "center",
                  justifyContent: "center",
                }}
              >
                <tbody>
                  <tr>
                    <td style={{ textAlign: "center", border: "1px solid" }}>
                      Mobile No.
                    </td>
                    <td style={{ textAlign: "center", border: "1px solid" }}>
                      Name
                    </td>
                    <td style={{ textAlign: "center", border: "1px solid" }}>
                      Employee Code
                    </td>
                    <td style={{ textAlign: "center", border: "1px solid" }}>
                      Punch_In
                    </td>
                    <td style={{ textAlign: "center", border: "1px solid" }}>
                      Punch_In_Notes
                    </td>
                    <td style={{ textAlign: "center", border: "1px solid" }}>
                      Punch_Out
                    </td>
                    <td style={{ textAlign: "center", border: "1px solid" }}>
                      Punch_Out_Notes
                    </td>
                    <td style={{ textAlign: "center", border: "1px solid" }}>
                      Duration_(Hours)
                    </td>
                    <td style={{ textAlign: "center", border: "1px solid" }}>
                      Remark
                    </td>
                  </tr>
                  {punchint.map((record) => {
                    const punchinTime = moment(record.punchin);
                    const punchoutTime = moment(record.punchout);

                    var hoursDiff =
                      punchoutTime.diff(punchinTime, "hours") % 60;

                    var minutesDiff =
                      punchoutTime.diff(punchinTime, "minutes") % 60;

                    var secondsDiff =
                      punchoutTime.diff(punchinTime, "seconds") % 60;

                    var a;
                    if (record.punchin && record.punchout) {
                      a = record.punchout.split("T")[1].split(".")[0];
                    } else {
                      a = "";
                    }
                    return (
                      <tr key={record.sno}>

                        <td
                          style={{ textAlign: "center", border: "1px solid" }}
                        >
                          {record.mobile}
                        </td>
                        <td
                          style={{ textAlign: "center", border: "1px solid" }}
                        >
                          {record.name}
                        </td>
                        <td
                          style={{ textAlign: "center", border: "1px solid" }}
                        >
                          {record.emp_code}
                        </td>
                        <td
                          style={{ textAlign: "center", border: "1px solid" }}
                        >
                          {record.punchin.split("T")[1].split(".")[0]}
                        </td>
                        <td
                          style={{ textAlign: "center", border: "1px solid" }}
                        >
                          {record.punchin_note}
                        </td>
                        <td
                          style={{ textAlign: "center", border: "1px solid" }}
                        >
                          {a}
                        </td>
                        <td
                          style={{ textAlign: "center", border: "1px solid" }}
                        >
                          {record.punchout_note}
                        </td>
                        <td
                          style={{ textAlign: "center", border: "1px solid" }}
                        >
                          {a
                            ? [
                              hoursDiff +
                              ":" +
                              minutesDiff +
                              ":" +
                              secondsDiff,
                            ]
                            : ""}
                        </td>
                        <td
                          style={{ textAlign: "center", border: "1px solid" }}
                        >
                          {record.remark}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              padding: 20,
              margin: 10,
              background: "#FFF",
              width: "100%",
              borderRadius: 10,
              overflowX: "scroll",
              marginTop: !matches ? "1%" : "15%",
            }}
          >
            <div>
              <div>
                <Grid
                  container
                  spacing={2}
                  style={{ justifyContent: "center", alignItems: "center" }}
                >
                  <Grid item xs={12} md={6}>
                    <div>
                      <p style={{ fontWeight: "bold" }}>
                        Employee Name: {allAttendence[0]?.name}
                      </p>
                    </div>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <div>
                      <p style={{ fontWeight: "bold" }}>
                        Employee Id: {employeeid}
                      </p>
                    </div>
                  </Grid>
                </Grid>
              </div>
              <table
                style={{
                  border: "1px solid",
                  width: "100%",
                  textAlign: "center",
                  justifyContent: "center",
                }}
              >
                <tbody>
                  <tr>
                    <td style={{ textAlign: "center", border: "1px solid" }}>
                      Name
                    </td>
                    <td style={{ textAlign: "center", border: "1px solid" }}>
                      Date
                    </td>
                    <td style={{ textAlign: "center", border: "1px solid" }}>
                      Punch_In
                    </td>
                    <td style={{ textAlign: "center", border: "1px solid" }}>
                      Punch_In_Notes
                    </td>
                    <td style={{ textAlign: "center", border: "1px solid" }}>
                      Punch_Out
                    </td>
                    <td style={{ textAlign: "center", border: "1px solid" }}>
                      Punch_Out_Notes
                    </td>
                    <td style={{ textAlign: "center", border: "1px solid" }}>
                      Duration_(Hours)
                    </td>
                    <td style={{ textAlign: "center", border: "1px solid" }}>
                      Remark
                    </td>
                  </tr>
                  {allAttendence?.map((record) => {
                    const punchinTime = moment(record.punchin);
                    const punchoutTime = moment(record.punchout);

                    var hoursDiff =
                      punchoutTime.diff(punchinTime, "hours") % 60;

                    var minutesDiff =
                      punchoutTime.diff(punchinTime, "minutes") % 60;

                    var secondsDiff =
                      punchoutTime.diff(punchinTime, "seconds") % 60;

                    var a;
                    if (record.punchin && record.punchout) {
                      a = record.punchout.split("T")[1].split(".")[0];
                    } else {
                      a = "";
                    }
                    return (
                      <tr key={record.sno}>
                        <td
                          style={{ textAlign: "center", border: "1px solid" }}
                        >
                          {record.name}
                        </td>
                        <td
                          style={{ textAlign: "center", border: "1px solid" }}
                        >
                          {record.punchin.split("T")[0]}
                        </td>
                        <td
                          style={{ textAlign: "center", border: "1px solid" }}
                        >
                          {record.punchin.split("T")[1].split(".")[0]}
                        </td>
                        <td
                          style={{ textAlign: "center", border: "1px solid" }}
                        >
                          {record.punchin_note}
                        </td>
                        <td
                          style={{ textAlign: "center", border: "1px solid" }}
                        >
                          {a}
                        </td>
                        <td
                          style={{ textAlign: "center", border: "1px solid" }}
                        >
                          {record.punchout_note}
                        </td>
                        <td
                          style={{ textAlign: "center", border: "1px solid" }}
                        >
                          {a
                            ? [
                              hoursDiff +
                              ":" +
                              minutesDiff +
                              ":" +
                              secondsDiff,
                            ]
                            : ""}
                        </td>
                        <td
                          style={{ textAlign: "center", border: "1px solid" }}
                        >
                          {record.remark}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}

      <div>
        <button type="button" className="btn btn-outline-sysmode btn-sm" onClick={(e) => handleAction('DOWNLOAD')}>Download</button>
        <CSVLink
          data={dataForDownload}
          filename="data.csv"
          className="hidden"
          ref={csvLink}
          target="_blank" />
      </div>
    </div>
  );
};
