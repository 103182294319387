import React, { useState, useEffect } from "react";
import "./sidebar.css";
import { Routes, Route, Link, NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import ag from "../../assets/images/favicon.ico";
import { Time } from "../attendance/Time";
// import bg from "../../assets/images/1.gif";
import Record from "../attendance/Record";
import { AdminRecord } from "../attendance/AdminRecord";
import { Attendance } from "../attendance/Attendece";

export const Sidebar = (props) => {
  const [activeLink, setActiveLink] = useState("");
  // const [adminSidebar, setAdminSidebar] = useState(false);
  const navigate = useNavigate();
  let userInfo = JSON.parse(localStorage.getItem("user-info"));
  let user1 = userInfo?.first_name;
  let user2 = userInfo?.last_name;
  let userType = userInfo?.user_type;
  useEffect(() => {
    console.log(userInfo, "user1");
    if(!userInfo){
    alert("please login")
    navigate("/login");
    }
  });
 

  const logout = () => {
    localStorage.clear();
    navigate("/login");
  };

  const toggleSidebar = () => {
    const sidebar = document.querySelector(".sidebar");
    const sidebarBtn = document.querySelector(".sidebarBtn");
    sidebar.classList.toggle("active");
    if (sidebar.classList.contains("active ")) {
      sidebarBtn.classList.replace("bx-menu", "bx-menu-alt-right");
    } else {
      sidebarBtn.classList.replace("bx-menu-alt-right", "bx-menu");
    }
  };

  const handleLinkClick = (linkName) => {
    setActiveLink(linkName);
    toggleSidebar();
  };

  // Loader effect
  // const [isLoading, setIsLoading] = useState(false);

  // useEffect(() => {
  //   setIsLoading(true);
  //   setTimeout(() => {
  //     setIsLoading(false);
  //   }, 2000);
  // }, []);

  return (
    <>
      <div>
        {/* {isLoading ? (
          <div>
            <div class="image-container">
              <img src={bg} alt="" />
            </div>
          </div>
        ) : ( */}
          <div>
            <div className="sidebar">
              <div className="logo-details" style={{ overflow: "hidden" }}>
                <i className="bx ">
                  <img src={ag} style={{ marginLeft: "25px" }} alt="" />
                </i>
                <span className="logo_name"> HumHai</span>
                <hr style={{ backgroundColor: "black", color: "black" }} />
              </div>
              <ul className="nav-links " style={{ overflow: "hidden" }}>
                <li>
                  <Link
                    to="/sidebar"
                    className={activeLink === "Dashboard" ? "active" : ""}
                    onClick={() => handleLinkClick("Dashboard")}>
                    <i className="bx bx-grid-alt"></i>
                    <span className="links_name">Dashboard</span>
                  </Link>
                </li>
                <li>
                  <Link
                    className={`dropdown ${
                      (activeLink === "Attendance" ||
                        activeLink === "time" ||
                        activeLink === "record" ||
                        activeLink === "records") &&
                      "active"
                    }`}
                  >
                    <i className="bx bx-box"></i>
                    <span className="links_name">
                      Attendance<i className="bx bx-chevron-down"></i>
                    </span>

                    <div
                      className="dropdown-content"
                      style={{
                        overflow: "hidden",
                        position: "absolute",
                        top: "70%",
                        left: "30%",
                      }}
                    >
                      <NavLink
                        to="/sidebar/time"
                        className={activeLink === "time" ? "active" : ""}
                        onClick={() => {
                          handleLinkClick("time");
                        }}
                      >
                        Punch In/Out
                      </NavLink>

                      <NavLink
                        to="/sidebar/record"
                        className={activeLink === "record" ? "active" : ""}
                        onClick={() => handleLinkClick("record")}
                      >
                        My Records
                      </NavLink>
                      {
                       userType === 0 || userType===6? (
                      <NavLink
                        to="/sidebar/adminrecord"
                        className={activeLink === "records" ? "active" : ""}
                        onClick={() => handleLinkClick("records")}
                      >
                        Admin Records
                      </NavLink>
                       ):""
                      }
                      <NavLink
                        to="/sidebar/admin"
                        className={activeLink === "recordss" ? "active" : ""}
                        onClick={() => handleLinkClick("records")}
                      >
                        All Records
                      </NavLink>
                    </div>
                  </Link>
                </li>

                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>

                <li className="log_out" style={{ overflow: "hidden" }}>
                  <a href="/" onClick={logout}>
                    <i className="bx bx-log-out"></i>
                    <span className="links_name">Log out</span>
                  </a>
                </li>
              </ul>
            </div>

            <section className="home-section">
              <div>
                <nav>
                  <div className="sidebar-button" onClick={toggleSidebar}>
                    <i className="bx bx-menu sidebarBtn"></i>
                    <span className="dashboard"></span>
                  </div>
                  <div className="search-box">
                    <input type="text" placeholder="Search..." />
                    <i className="bx bx-search"></i>
                  </div>
                  <div className="profile-details">
                    <img src={ag} alt="" />
                    <span className="admin_name">{user1 + " " + user2}</span>
                  </div>
                </nav>
              </div>

              <div>
                <div class="home-content">
                  <Routes>
                    <Route path="/time" element={<Time />} />
                    <Route path="/record" element={<Record />} />
                    <Route path="/adminrecord" element={<AdminRecord />} />
                    <Route path="/admin" element={<Attendance />} />

                  </Routes>
                </div>
              </div>
            </section>
          </div>
        {/* )} */}
      </div>
    </>
  );
};
